import { Text } from '@workos-inc/component-library';
import { NextPageContext } from 'next';
import Head from 'next/head';
import React from 'react';

interface ErrorProps {
  statusCode: number;
}

class ErrorPage extends React.Component<ErrorProps> {
  static async getInitialProps({
    res,
    err,
  }: NextPageContext): Promise<ErrorProps> {
    // eslint-disable-next-line no-nested-ternary
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return {
      statusCode: statusCode || 404,
    };
  }

  render(): JSX.Element {
    const { statusCode } = this.props;
    const title = statusCode < 500 ? 'Page Not Found' : 'Internal Error';
    return (
      <div>
        <Head>
          <title>{title}</title>
        </Head>
        <section className="flex flex-col items-center justify-center min-h-screen p-12">
          <div className="mb-8">
            <img
              className="w-48 h-48"
              src="https://workos.imgix.net/dashboard/cube.png"
            />
          </div>
          <Text as="h1" className="mb-8" size="xlarge" weight="medium">
            {title}
          </Text>

          <Text as="p">
            You can{' '}
            <a
              className="text-primary hover:underline"
              href={process.env.NEXT_PUBLIC_DASHBOARD2_URL}
            >
              return to the dashboard
            </a>{' '}
            or{' '}
            <a
              className="text-primary hover:underline"
              href="mailto:support@workos.com"
            >
              contact support
            </a>{' '}
            if you need help.
          </Text>
        </section>
      </div>
    );
  }
}

export default ErrorPage;
